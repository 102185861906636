import React from "react";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";
import { theme_tokens } from "../tokens/theme";
import { design_tokens } from "../tokens/design";
import App from "../components/App";
import Text from "../components/Text";
import Links from "../components/Links";
import Link from "../components/Link";
import Emoji from "../components/emoji";

import { Container, Row, Column } from "../components/Grid";

const TypographyWrapper = styled.div`
  color: ${({ theme }) => theme_tokens[theme.mode].primary};

  h1 {
    ${design_tokens.content.h1}
  }

  h2 {
    ${design_tokens.content.h2}
  }

  h2 {
    ${design_tokens.content.h3}
  }

  p {
    ${design_tokens.content.p}
  }

  ul {
    margin-left: 16px;
    margin-bottom: 0;
    padding-bottom: 32px;
    list-style: none;
  }

  li {
    list-style-image: url("/circle.svg");
    margin: 0;
    padding-bottom: 8px;
  }
`;

const Post = ({ data, theme }) => {
  const post = data.markdownRemark;

  return (
    <App>
      <Container>
        <Row>
          <Column fill={2}>
            <Text variant="body">
              <Link to="/">
                <Emoji label="Back" emoji="👈" /> Let's go back home
              </Link>
            </Text>
            <Text variant="heading">{post.frontmatter.title}</Text>
          </Column>
          <Column fill={1}></Column>
        </Row>
        <Row>
          <Column fill={2}>
            <TypographyWrapper
              theme={theme}
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
          </Column>
          <Column fill={1}>
            <Row always>
              <Column fill={1} of={2} always>
                <Text variant="label" colour="accent">
                  Published on
                </Text>
                <Text variant="body">{post.frontmatter.date}</Text>
              </Column>
              <Column fill={1} of={2} always>
                <Text variant="label" colour="accent">
                  Topic
                </Text>
                <Text variant="body">{post.frontmatter.tag}</Text>
              </Column>
            </Row>
            <Row spacing={2}>
              <Column>
                <Links />
              </Column>
            </Row>
          </Column>
        </Row>
      </Container>
    </App>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        tag
      }
    }
  }
`;

export default withTheme(Post);
